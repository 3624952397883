import React from 'react'
import ThanksTemplate from '../../templates/ThanksTemplate'

const FreebyThanksPage = ({ location }) => {
    return (
        <ThanksTemplate location={location} />
    )
}

export default FreebyThanksPage
